import React, {useState, Fragment, useEffect} from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useFlexSearch } from 'react-use-flexsearch'
import TutorialTiles from './components/TutorialTiles'
import { FaSearch } from 'react-icons/fa'
import LearningPathTiles from '../learn/components/LearningPathTiles'
import FilterComponent from "./components/filters"
import HeroBanner from "./components/HeroBanner"
import LearnMoreComponent from './components/LearnMore'
import {getQueryParams, useQueryParamString} from 'react-use-query-param-string';


const Tutorials = ({ data }) => {
  const index = data.localSearchContent.index
  const store = data.localSearchContent.store
  const [searchString, setSearchString] = useQueryParamString('query', '');
  const [, setSelectedLanguagesParam] = useQueryParamString('languages', '');
  const [selectedLanguages, setSelectedLanguages] = useState(Array.isArray(getQueryParams().languages) ? getQueryParams().languages : getQueryParams().languages ? [getQueryParams().languages] : []);
  const [, setSelectedTagsParam] = useQueryParamString('tags', '');
  const [selectedTags, setSelectedTags] = useState(Array.isArray(getQueryParams().tags) ? getQueryParams().tags : getQueryParams().tags ? [getQueryParams().tags] : [])
  const [selectedTechnology, setSelectedTechnology] = useQueryParamString('technology', '');
  const [selectedContentType, setSelectedContentType] = useQueryParamString('type', '')

  const handleSelectedLanguages = (v) => {
    setSelectedLanguages(v)
    setSelectedLanguagesParam(v)
  }

  const onSelectContentType = (_value) => {
    if (!_value) {
      setShowAllTutorials(false);
      setShowAllLearningPaths(false)
      setShowAllQuickstart(false)
    }
    setSelectedContentType(_value)
  }

  let results = useFlexSearch(searchString, index, store);
  let filteredContent = data.tutorials.nodes;
  if (searchString) {
    results = query
      ? results.map((result) => {
        const {
          path,
          filter,
          description,
          title,
          tags,
          length,
          content_type,
          sdk_language,
          technology,
        } = result
        return {
          frontmatter: {
            path,
            filter,
            description,
            title,
            tags,
            length,
            content_type,
            sdk_language,
            technology,
          },
        }
      })
      : data.allMarkdownRemark.nodes;
    filteredContent = results;
  }

  if (selectedLanguages && selectedLanguages.length) {
    filteredContent = filteredContent.filter((content_item) => {
      if (content_item.frontmatter.sdk_language !== null) {
        return (
          content_item.frontmatter.sdk_language.filter((value) =>
            selectedLanguages.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  if (selectedTags.length > 0) {
    filteredContent = filteredContent.filter((content_item) => {
      if (content_item.frontmatter.tags !== null) {
        return (
          content_item.frontmatter.tags.filter((value) =>
            selectedTags.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  let tech = filteredContent.map((content) => content.frontmatter.technology)
  const contentLength = filteredContent.length

  if (selectedTechnology.length > 0) {
    filteredContent = filteredContent.filter((content_item) => {
      if (content_item.frontmatter.technology !== null) {
        return (
          content_item.frontmatter.technology.filter((value) =>
            selectedTechnology.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  const [isShowAllTutorials, setShowAllTutorials] = useState(false);
  const tutorials = filteredContent.filter((content_item) => content_item.frontmatter.content_type === 'tutorial')
  const tutorialsShown = isShowAllTutorials ? tutorials : [...tutorials].splice(0, 4);

  const [isShowAllLearningPaths, setShowAllLearningPaths] = useState(false);
  const learningPaths = filteredContent.filter((content_item) => content_item.frontmatter.content_type === 'learn')
  const learningPathsShown = isShowAllLearningPaths ? learningPaths : [...learningPaths].splice(0, 4);
// todo: do these in the URL also
  const [isShowAllQuickstart, setShowAllQuickstart] = useState(false);
  const quickstarts = filteredContent.filter((content_item) => content_item.frontmatter.content_type === 'quickstart')
  const quickstartsPathsShown = isShowAllQuickstart ? quickstarts : [...quickstarts].splice(0, 4);

  const [isShowAllContent, setShowAllContent] = useState(false);
  let searchedContent = [...filteredContent]
  if (!selectedContentType) {
    searchedContent = [...quickstarts, ...tutorials, ...learningPaths]
  }
  if (selectedContentType === "Quickstart Guides") {
    searchedContent = quickstarts
  }

  if (selectedContentType === "Tutorials") {
    searchedContent = tutorials
  }

  if (selectedContentType === "Learning Paths") {
    searchedContent = learningPaths
  }

  const [input, setInput] = useState(searchString);

  useEffect(() => {
    setInput(searchString);
  }, [searchString]);

  const showAllLearningPaths = () => {
    setShowAllLearningPaths(true)
  }
  const showAllTutorials = () => {
    setShowAllTutorials(true);
  }
  const showAllQuickstarts = () => {
    setShowAllQuickstart(true);
  }

  const showAllContent = () => {
    setShowAllContent(true);
  }

  const handleSearch = () => {
    setSearchString(input.trim());
  }

  const clearSearch = () => {
    setSearchString("");
    setInput("");

    setSelectedLanguages([]);
    setSelectedLanguagesParam("");

    setSelectedTags([]);
    setSelectedTagsParam("");

    setSelectedTechnology("");
    setSelectedContentType("");

  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearch()
    }
  }

  return (
    <Fragment>
      <HeroBanner />
      <div className="page--tutorials wrapper">
        <Helmet title={`Tutorials | Couchbase Developer Portal`} >
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no user-scalable=no"/>
          </Helmet>
        <div className='pt-4 pb-12 text-center wrapper'>
          <h2>Learn with Couchbase Tutorials</h2>
          <p>The tutorials and learning paths available cover a wide range of topics related to Couchbase. These topics include CRUD operations, SQL querying, transactions, building REST APIs, and more.</p>
        </div>
        <div className="search-wrapper">
          <input
            className="search-input"
            placeholder="Search"
            name="input"
            value={input}
            onChange={(event) => setInput(event.target.value)}
            onKeyDown={handleKeyPress}
          />
          <FaSearch className="search-icon" />
          <button className="search-button" onClick={handleSearch}>
            Search
          </button>
        </div>
        <div className='filter-accordions flex justify-between flex-wrap'>
          <FilterComponent
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={handleSelectedLanguages}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTagsParam}
            selectedTechnology={selectedTechnology}
            setSelectedTechnology={setSelectedTechnology}
            selectedContentType={selectedContentType}
            onSelectContentType={onSelectContentType}
            tech={tech}
            contentLength={contentLength}
            tutorials={tutorials}
            learningPaths={learningPaths}
            quickstarts={quickstarts}
          />
        </div>
        {searchString || selectedLanguages.length || selectedTags.length || selectedTechnology.length || selectedContentType.length ? (
          <div className="w-full lg:w-4/5 absolute">
            Showing {searchString ? 'search' : 'filtered'} results {searchString && 'for:'} {searchString ? `"${searchString}"` : ''} <button className="text-cb-orange" onClick={clearSearch}>Clear{searchString ? ' search' : ''}</button>
          </div>
        ) : ""}
        <div className="flex flex-row">
          <div className="w-full">
            {(filteredContent.length === 0) ||
            (selectedContentType === "Quickstart Guides" && !quickstarts.length) ||
            (selectedContentType === "Learning Paths" && !learningPaths.length) || 
            (selectedContentType === "Tutorials" && !tutorials.length) ?
             <p className="pt-8 pb-20">There are no results found in the category</p>:""}            
            {filteredContent.length > 0 && searchString && <Fragment>
              <LearningPathTiles
                learningPaths={isShowAllContent ? [...searchedContent] :
                  [...searchedContent].splice(0, 4)}
                gridTilesContainerClass={'grid-tiles-container-learningPaths'}
              />
              {!isShowAllContent && searchedContent.length > 4 && <div className="flex justify-center">
                <button className="see-all-button hover-fade" onClick={showAllContent}>
                  See All
                </button>
              </div>}
            </Fragment>}
            {filteredContent.length > 0 && !searchString && <Fragment>
              {(!selectedContentType || selectedContentType === "Quickstart Guides") && quickstarts.length ? (<Fragment>
                <div className="tutorial-heading mt-10 my-10">
                  <div className='text-2xl font-semibold' style={{ display: 'inline-block' }}>
                    Quickstart Guides
                  </div>
                </div>
                <LearningPathTiles
                  learningPaths={quickstartsPathsShown}
                  gridTilesContainerClass={'grid-tiles-container-learningPaths'}
                />
                {!isShowAllQuickstart && quickstarts.length > 4 && (
                  <div className="flex justify-center">
                    <button className="see-all-button hover-fade" onClick={showAllQuickstarts}>
                      See All
                    </button>
                  </div>
                )}
              </Fragment>) : ""}
              {(!selectedContentType || selectedContentType === "Learning Paths") && learningPaths.length ? (<Fragment>
                <div className="tutorial-heading mt-10 mb-10">
                  <div className='text-2xl font-semibold' style={{ display: 'inline-block' }}>
                    Learning Paths
                  </div>
                </div>
                <LearningPathTiles
                  learningPaths={learningPathsShown}
                  gridTilesContainerClass={'grid-tiles-container-learningPaths'}
                />
                {!isShowAllLearningPaths && learningPaths.length > 4 && (
                  <div className="flex justify-center">
                    <button className="see-all-button hover-fade" onClick={showAllLearningPaths}>
                      See All
                    </button>
                  </div>
                )}
              </Fragment>) : ""}
              {(!selectedContentType || selectedContentType === "Tutorials") && tutorials.length ? (<Fragment>
                <div className="tutorial-heading mt-10 mb-10">
                  <div className='text-2xl font-semibold' style={{ display: 'inline-block' }}>
                    Tutorials
                  </div>
                </div>
                <TutorialTiles
                  tutorials={tutorialsShown}
                  showCategories={false}
                  gridTilesContainerClass={'grid-tiles-container-tutorials'}
                />
                {!isShowAllTutorials && tutorials.length > 4 && (
                  <div className="flex justify-center">
                    <button className="see-all-button hover-fade mb-4" onClick={showAllTutorials}>
                      See All
                    </button>
                  </div>
                )}
              </Fragment>) : ""}
            </Fragment>}
          </div>
        </div>
      </div>
      <LearnMoreComponent />
    </Fragment>
  )
}

export default Tutorials

// Video on using gatsby-plugin-image: https://www.youtube.com/watch?v=zRtFwzF4p1o
// import style module to style this page
// sorting: https://www.youtube.com/watch?v=G3KkHk8iSOg
// multi querys: https://www.youtube.com/watch?v=WmSJMj-gNKo

export const query = graphql`
  query tutorialsQuery {
    tutorials: allMarkdownRemark(
      filter: {
        frontmatter: {
          content_type: { in: ["tutorial", "learn", "quickstart"] },
          exclude_tutorials: { in: [ null, false] }
        }
      }
    ) {
      nodes {
        frontmatter {
          path
          content_type
          title
          technology
          filter
          description
          length
          tags
          sdk_language          
          exclude_tutorials
        }
        id
      }
    }
    localSearchContent {
      index
      store
    }
    tutorialImages: allFile(
      filter: {
        ext: { regex: "/(.jpg|.gif|.png)/" }
        relativeDirectory: { in: ["tutorial", "quickstart"] }
      }
    ) {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          ext
        }
      }
    }
  }
`
